// import $ from 'jquery'

module.exports = () => {
  var activeInViewport = (el) => {
    var elements = document.querySelectorAll(el)
    for (let i = 0; i < elements.length; i++) {
      if (isActiveInViewport(elements[i])) {
        elements[i].classList.add('active')
      }
    }
  }
  var isActiveInViewport = (elem) => {
    var bounding = elem.getBoundingClientRect()
    return (
      (bounding.top <= ((window.innerHeight / 100) * 85)))
  }
  activeInViewport('.animate')
  window.addEventListener('scroll', function () {
    activeInViewport('.animate')
  })
}
