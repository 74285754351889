
// https://www.30secondsofcode.org/js/s/element-is-visible-in-viewport/#:~:text=We%20can%20do%20this%2C%20using,the%20dimensions%20of%20the%20viewport.
const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  const navGuessedHeight = 200;
  return partiallyVisible
    ? ((top > 0 && top < (innerHeight)) ||
      (bottom > 0 && bottom < innerHeight)) &&
    ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= navGuessedHeight && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

const playInViewVideo = ($container) => {
  if (typeof window.autoPlayVideoList !== 'undefined' && typeof window.autoPlayVideoList[$container.attr('id')] !== 'undefined' && window.autoPlayDisabled !== true) {
    let isFullyVisible = elementIsVisibleInViewport($container[0]);
    if (isFullyVisible) {
      window.autoPlayVideoList[$container.attr('id')].play();
    } else {
      window.autoPlayVideoList[$container.attr('id')].pause();
    }
  }
  else {
    $('body').trigger('pauseAllVideos')
  }
}

const playVideoOnEnteringView = ($) => {
  $(window).on('scroll', function () {
    $('.js-auto-play-wistia-video').each(function (index) {
      if (window.autoPlayDisabled) {
        window.autoPlayVideoList[$(this).attr('id')].pause();
        return;
      }
      let $container = $(this)
      let isDesktopTabbedContent = $container.closest('.desktop-tabbed-image-text').length;
      let isMobileAccordionedContent = $container.closest('.mobile-tabbed-image-text').length;

      let isContained = isDesktopTabbedContent ||isMobileAccordionedContent;
      if (isContained) {
        if ($container.closest('.desktop-tabbed-image-text').css('display') !== 'none') {
          if (isDesktopTabbedContent) {
            playInViewVideo($container);
          }
        }
        // cannot play the mobile version
        // if ($container.closest('.mobile-tabbed-image-text').css('display') !== 'none') {
        //   if (isMobileAccordionedContent) {
        //     playInViewVideo($container);
        //   }
        // }
      } else {
        playInViewVideo($container);
      }
    })
  })
}

export default playVideoOnEnteringView
