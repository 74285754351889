import 'jquery'

const closeAllMobileSubNav = () => {
  $('.js-match-sub-sub-drop').removeClass('selected')
  $('.js-mobile-dropdown-toggle').each(function () {
    let $toggle = $(this)
    let $target = $($toggle.attr('data-target'))
    $target.removeClass('show')

    setTimeout(function () {
      $toggle.removeClass('active')
      $target.removeAttr('aria-model', 'true')
      $target.removeAttr('role', 'dialog')
      $target.attr('aria-hidden', 'true')
      $target.hide()
    }, 300)
  })
}

const handleNav = ($) => {
  $('.header-content').find('.burger').first().on('click', function () {
    let $target = $($(this).attr('data-target'))
    $target.addClass('show')
    $target.show()
  })
  $('.mobile-navigation').find('.close').first().on('click', function () {
    $(this).closest('.modal').hide()
  })
  $('.js-sub-drop-content-back-button').each(function () {
    $(this).on('click', function (e) {
      e.preventDefault()
      let $target = $($(this).attr('data-target'))
      $target.find('a').each(function () {
        $(this).attr('tabindex', -1)
        $(this).attr('aria-hidden', true)
      })
      $target.removeClass('selected')
    })
  })

  $('.menu-item-has-children').each(function () {
    let $menuItem = $(this)
    $menuItem.find('.dropdown-toggle').first().unbind('click')
    $menuItem.find('.dropdown-toggle').first().on('click',function (e) {
      e.preventDefault()
      openCloseSubNav({data: {$menuItem}})
    })
  })

  $(".overlay.main-overlay").on('click', function () {
    $('.overlay.main-overlay').removeClass('active')
    $('.menu-item-has-children').removeClass('active')
  })

  $('.js-has-grand-children').each(function () {
    let $btnOpensGrandChild = $(this)
    $btnOpensGrandChild.on('click', function (e) {
      e.preventDefault()
      let $target = $($(this).attr('data-target'))
      if (!$target.hasClass('selected')) {
        $target.addClass('selected')
        $target.find('a').each(function (e) {
          $(this).attr('tabindex', 1)
          $(this).attr('aria-hidden', false)
        })
      }
    })
  })

  function openCloseSubNav (event) {
    if (!event.data.$menuItem.hasClass('active')) {
      $('.menu-item-has-children').removeClass('active')
      $('.overlay.main-overlay').addClass('active')
      event.data.$menuItem.addClass('active')
    } else {
      $('.overlay.main-overlay').removeClass('active')
      event.data.$menuItem.removeClass('active')
    }
  }

  $('.js-mobile-dropdown-toggle').each(function () {
    let $toggle = $(this)
    let $target = $($toggle.attr('data-target'))
    $toggle.on('click', function (e) {
      e.preventDefault()
      if (!$toggle.hasClass('active')) {
        $toggle.addClass('active')
        $target.show()
        setTimeout(function () {
          $target.addClass('show')
          $target.attr('aria-model', 'true')
          $target.attr('role', 'dialog')
          $target.removeClass('aria-hidden')
        }, 100)
      }
    })
  })

  // when burger is clicked, sub navs are reset
  $('.burger').on('click', closeAllMobileSubNav)

  $('.js-closes-mobile-subnav').each(function () {
    let $btnClose = $(this)
    $btnClose.on('click', function () {
      // the bootstrap version didn't work out well
      // $($btnClose.attr('data-target')).modal('hide');
      closeAllMobileSubNav ()
    })
  })
}

export default handleNav
