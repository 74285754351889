import $ from 'jquery'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/modal'
// import 'bootstrap'
import animate from './animate'
// import { navScroller } from './components/navScroller'
// import { videoModal } from './components/videoModal'
import { resetFilters } from './components/resetFilters'
// import { adminBarAdjust } from './components/adminBarAdjust'
import { animatedWorkflow} from './components/animatedWorkflow'
// import { acfSelectFix } from './components/acfSelectFix'
// import { showHideForms } from './components/showHideForms'
// import { blockTags } from './components/blockTags'
// import { stickyPricing } from './components/stickyPricing'
// import { jiraSupport } from './components/jiraSupport'
// import { equalHeightRow } from './components/equalHeightRow'
// import { priceUpdate } from './components/priceUpdate'
// import { showHideFeatures } from './components/showHideFeatures'
// import { eventTracking } from './components/eventTracking'
// import { ajaxFilters } from './components/ajaxFilters'
// import { scrollButton } from './components/scrollButton'
// import { scrollNav } from './components/scrollNav'
import handleFooterNavMobile from './components/footerNavMobile'
import imageVideoModal from './components/imageVideoModal'
import 'jquery-match-height'
import handleNav from './components/research-nav'
import pricePlanAssetCountSelect from './components/pricePlanAssetCountSelect'
import handleAnchorLinkScrollTo from './components/handleAnchorLinkScrollTo'
import handleContactUsFormSwap from './components/contactUsFormSwap'
import slickCarouselConfig from './components/slickCarouselConfig'
import playVideoOnEnteringView from './components/playVideoOnEnteringView'
import connectorPluginListing from './components/connectorPluginListing'
import generalHigDropDown from './components/generalHigDropDown'
import { eventCardSpeakerHandler } from './components/eventCardSpeakerHandler'
import { eventListingSeeMore } from './components/eventListingSeeMore'

require('./../sass/a_bootstrap-includes.scss')
require('./../sass/style.scss')

// jQuery document ready:
$(() => {
  handleFooterNavMobile($)
  handleNav($)
  pricePlanAssetCountSelect($)
  handleAnchorLinkScrollTo($)
  handleContactUsFormSwap($)
  slickCarouselConfig($)
  playVideoOnEnteringView($)
  connectorPluginListing($)
  eventCardSpeakerHandler()
  eventListingSeeMore()

  //accessibility for the really bad sticky nav solution
  $('#navShadow').find('a').attr('tabindex', '-1')
  $('#navShadow').find('button').attr('tabindex', '-1')

  $('.js-match-sub-sub-drop').matchHeight({row: false})
  $('.js-card-research-areas-cta').matchHeight({row: true})

  $('.js-card-resource-title').matchHeight({row: true})
  $('.js-card-horizontal-title').matchHeight({row: true})
  $('.js-card-horizontal-text').matchHeight({row: true})
  setTimeout(function () {
    $('.js-card-horizontal').matchHeight({row: true})
  }, 1000)

  $('.js-contact-us-card-text').matchHeight({row: true})
  $('.js-card-pricing-title').matchHeight({row: true})
  $('.js-card-pricing-plan-content-bullets').matchHeight({row: true})
  $('.js-pricing-card-note').matchHeight({row: true})
  $('.js-pricing-card-note-title').matchHeight({row: true})
  $('.js-card-pricing-plan-content').matchHeight({row: true})
  $('.js-event-card-eyebrow').matchHeight({row: true})
  $('.js-event-card-text').matchHeight({row: true})
  $('.js-event-card-speakers').matchHeight({row: true,  property: 'min-height',})
  $('#mainLoginModal').addClass('active')

  // setTimeout(function () {
    $('.js-match-main-login-modal-text-height').matchHeight({row: true,  property: 'min-height',})
    // setTimeout(function () {
      $('#mainLoginModal').removeClass('active')
  //   } , 400)
  // }, 1000)

  //
  // $('.js-card-pricing-plan').matchHeight({row: true})
  // setTimeout(() => $('.js-card-pricing-plan').matchHeight({row: true}),  200)
  // setTimeout(() => $('.js-card-pricing-plan-content-bullets').matchHeight({row: true}),  400)
  // $('.js-tab-pane').matchHeight({row: true})

  generalHigDropDown(window.jQuery, 'js-general-hig-drop-down')

  imageVideoModal(window.jQuery)

  if ($('.block--animated-workflow').length) {
    animatedWorkflow()
  }

  if ($('#resetFilterId').length) {
    resetFilters()
  }
  if ($('.animate').length) {
    animate()
  }

  $('.js-main-login-modal-close').on('click', function () {
    let tar = $(this).attr('data-target')
    if (tar) {
      $(tar).removeClass('active')
      window.autoPlayDisabled = false
      $('body').removeClass('m-0')
    }
  })
  $('.js-open-target-modal').on('click', function (e) {
    let tar = $(this).attr('data-target')
    if (tar && $(tar).length && !$(tar).hasClass('active')) {
      e.preventDefault()
      $(tar).addClass('active')
      let modalType = $(this).attr('data-modal-type')
      $(tar).removeClass('login').removeClass('signup').addClass(modalType)
      window.autoPlayDisabled = true
      $('body').addClass('m-0')
      $('body').trigger('pauseAllVideos')
    }
  })

  if ($('.jump-menu__list a').length) {
    // eventTracking()
  }

})

$('body').on('pauseAllVideos', function () {
  for (let el in window.autoPlayVideoList) {
    window.autoPlayVideoList[el].pause()
  }
})
