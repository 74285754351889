import $ from 'jquery'
import autoComplete from '@tarekraafat/autocomplete.js/dist/autoComplete'
const txtSelectOption = 'Select option'
const getListOfPhrases = ($) => {
  let $cards = $('.js-connector-plugin-card');
  let phrases = [];
  $cards.each(function () {
    let $card = $(this)
    let title = $card.find('.js-connector-plugin-card-title').text().trim()
    // let text = $card.find('.js-connector-plugin-text').text().trim()
    if (!phrases.includes(title)) {
      phrases.push(title)
    }
    // if (!phrases.includes(text)) {
    //   phrases.push(text)
    // }
  })
  return phrases
}
const handleAutoComplete = ($) => {
  if (!getListOfPhrases($).length) return;
  console.log('after')
  const autoCompleteJS = new autoComplete(
// API Advanced Configuration Object
    {
      selector: "#autoComplete",
      placeHolder: "Search",
      data: {
        src: getListOfPhrases($),
        cache: true,
      },
      resultsList: {
        element: (list, data) => {
          if (!data.results.length) {
            // Create "No Results" message element
            const message = document.createElement("div");
            // Add class to the created element
            message.setAttribute("class", "no_result");
            // Add message text content
            message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
            // Append message element to the results list
            list.prepend(message);
          }
        },
        noResults: true,
      },
      resultItem: {
        highlight: true
      },
      events: {
        input: {
          selection: (event) => {
            const selection = event.detail.selection.value;
            autoCompleteJS.input.value = selection;
          }
        }
      }
    }
  );
}

const tagCardsToBeActiveBySelectedFilter = ($container, filter) => {
  const cardSelector = $container.find('.js-connector-plugin-card.to-be-active').length ? '.js-connector-plugin-card.to-be-active' : '.js-connector-plugin-card'
  $container.find(cardSelector).each(function () {
    let $card = $(this)
    let isFiltered = filter ? $card.attr('data-type') === filter : true
    let show = isFiltered

    if (show && !$card.hasClass('active')) {
      $card.addClass('to-be-active')
    } else {
      $card.removeClass('to-be-active')
    }
  })
}

const tagCardsToBeActiveBySearch = ($container, search) => {
  const cardSelector = $container.find('.js-connector-plugin-card.to-be-active').length ? '.js-connector-plugin-card.to-be-active' : '.js-connector-plugin-card'
  if (search && typeof search === 'string') {
    $container.find(cardSelector).each(function () {
      let $card = $(this)
      let title = $card.find('.js-connector-plugin-card-title').text().trim()
      // let text = $card.find('.js-connector-plugin-text').text().trim()

      if (title.toLowerCase().includes(search.toLowerCase())
        // || text.toLowerCase().includes(search.toLowerCase())
      ) {
        $card.addClass('to-be-active')
      } else {
        $card.removeClass('to-be-active')
      }
    })
  }
}
const showToBeActive = ($container, cb = null) => {
  setTimeout(function () {
    $container.find('.to-be-active').each(function () {
      let $card = $(this)
      $card.parent().show()
      $card.addClass('active')
    })
    $container.find('.to-be-active').removeClass('to-be-active')
  }, 350)
  setTimeout(function () {
    $container.find('.js-button-see-more').attr('data-count', $container.find('.js-connector-plugin-card.active').length)
    if (cb) cb()
  }, 500)
}

const hideAllCards = () => {
  $('.js-connector-plugin-card').each(function () {
    let $card = $(this)
    $card.removeClass('active')
    setTimeout(function () { $card.parent().hide() },300)
  })
}

const resetSeeMoreButton = ($container) => {
  let defaultListCount = $container.find('.js-button-see-more').attr('data-count-max-default')
  let $btn = $container.find('.js-button-see-more')
  $btn.attr('data-count-max', parseInt(defaultListCount))
}

const showHideSeeMoreButton = ($container, maxPossible) => {
  let $btn = $container.find('.js-button-see-more')
  let selectedCardsCount = parseInt($btn.attr('data-count'))

  if (maxPossible > selectedCardsCount) {
    $btn.show()
  } else {
    $btn.hide()
  }
}
const closeDropDown = ($container) => {
  let $dropDownContent = $container.find('.js-hig-drop-down-options')
  if ($dropDownContent.hasClass('active')) {
    $dropDownContent.removeClass('active')
    $container.find('.arrow-up').addClass('d-none')
    $container.find('.arrow-down').removeClass('d-none')
  }
}
const toggleDropDown  = ($container) => {
  let $dropDownContent = $container.find('.js-hig-drop-down-options')
  if (!$dropDownContent.hasClass('active')) {
    $dropDownContent.addClass('active')
    $container.find('.arrow-up').removeClass('d-none')
    $container.find('.arrow-down').addClass('d-none')
  } else {
    closeDropDown($container)
  }
}
const connectorPluginListingTypeFilter = ($) => {
  $('.js-connector-plugins-listing').each(function () {
    let $this = $(this)
    $this.find('.js-selected-button').on('click', function () {
      toggleDropDown($this)
    })
    $this.find('.js-connector-plugin-type-filter-button')
      .each(function () {
        let $filterButton = $(this)
        $filterButton.on('click', function () {
          $this.find('.js-remove-selected-connector-plugin-type-filter').removeClass('active')
          if (!$(this).hasClass('active')) {
            $this.find('.js-connector-plugin-type-filter-button').removeClass('active')
            $(this).addClass('active')
            // update dropdown value
            $this.find('.js-selected-text').html( $filterButton.attr('data-target'))
          } else {
            $(this).removeClass('active')
            $this.find('.js-remove-selected-connector-plugin-type-filter').addClass('active')
          }
          toggleDropDown($this)
          $this.find('.js-btn-search').first().trigger('search', ['selectedFilter'])
        })
      })
  })
}
const btnSeeMore = ($container) => {
  $container.each(function () {
    let $this = $(this)
    $this.find('.js-button-see-more').on('click', function () {
      let maxDefault = parseInt($(this).attr('data-count-max-default'))
      let cardCount = $container.find('.js-connector-plugin-card.active').length
      let selectedAllCount = parseInt($(this).attr('data-count-selected-max'))

      $container.find('.js-button-see-more').attr('data-count-max', maxDefault + cardCount)
      doBtnSearch($container)

      // hide self when everything is visible
      if (($this.find('.js-connector-plugin-card.active').length + cardCount) >= selectedAllCount) {
        $(this).hide()
      }
    })
  })
}
const isMoreSeeMoreElements = ($container, filter) => {
  let maxPossible = $container.find('.js-connector-plugin-card').length
  if (filter) {
    maxPossible = $container.find('*[data-type="' + filter + '"]').length
  }
  return maxPossible
}
const doBtnAll = ($, $container) => {
  // unselects all filters
  $container.find('.js-connector-plugin-type-filter-button')
    .removeClass('active')
    .removeClass('to-be-active')

  // sets all cards to be shown
  $container.find('.js-connector-plugin-card').each(function () {
    let $card = $(this)
    if (!$card.hasClass('active')) {
      $card.addClass('to-be-active')
    }
  })

  // set all to be active
  $container.find('.js-remove-selected-connector-plugin-type-filter').addClass('active')
  if (!$(this).hasClass('active')) {
    $(this).addClass('active')
    $container.find('.js-selected-text').html(txtSelectOption)
  }
  closeDropDown($container)
}
const btnAll = ($) => {
  // js-remove-selected-connector-plugin-type-filter
  $('.js-connector-plugins-listing').each(function () {
    let $this = $(this)
    $this.find('.js-remove-selected-connector-plugin-type-filter').on('click', function () {
      doBtnAll($, $this)
      $this.find('.js-btn-search').first().trigger('search', ['searchInputValue'])
    })
  })
}
const reduceCardsCountToMax = ($container) => {
  let max = parseInt($container.find('.js-button-see-more').attr('data-count-max'))
  let activeCount = parseInt($container.find('.js-connector-plugin-card.active').length)
  let count = 0

  max = max - activeCount
  $container.find('.js-connector-plugin-card.to-be-active').each(function () {
    let $card = $(this)
    count++
    let isInCount = count <= max
    if (!isInCount) {
      $card.removeClass('to-be-active')
    }
  })
}

/**
 *
 * @param $container
 * @returns {number|*} 0 if no cards are selected
 */
const selectAllPossibleSearchResults = ($container, singleFilterName = '') => {
  let searchInputValue = ''
  let selectedFilter = ''

  switch (singleFilterName) {
    case 'searchInputValue':
       searchInputValue = $container.find('#autoComplete').val()
       $container.find('.js-connector-plugin-type-filter-button.active').removeClass('active')
       $container.find('.js-remove-selected-connector-plugin-type-filter').addClass('active')
       $container.find('.js-selected-text').html(txtSelectOption)
        closeDropDown($container)
      break
    case 'selectedFilter':
        searchInputValue = $container.find('#autoComplete').val('')
        selectedFilter = $container.find('.js-connector-plugin-type-filter-button.active').attr('data-target')
      break
    default:
      searchInputValue = $container.find('#autoComplete').val()
      selectedFilter = $container.find('.js-connector-plugin-type-filter-button.active').attr('data-target')

  }

  if (searchInputValue || selectedFilter) {
    $container.find('.js-connector-plugin-card.to-be-active').removeClass('to-be-active')
    if (selectedFilter) {
      tagCardsToBeActiveBySelectedFilter($container, selectedFilter)
    }
    if (searchInputValue) {
      tagCardsToBeActiveBySearch($container, searchInputValue)
    }
    return $container.find('.js-connector-plugin-card.to-be-active').length
  }
  doBtnAll($, $container)
  return $container.find('.js-connector-plugin-card.to-be-active').length
}
const doBtnSearch = ($container, singleFilterName = '') => {
  hideAllCards()
  let maxPossible = selectAllPossibleSearchResults($container, singleFilterName)

  reduceCardsCountToMax($container)
  let loadedCount =  $container.find('.js-connector-plugin-card.active').length
  let toBeActiveCount = $container.find('.js-connector-plugin-card.to-be-active').length
  loadedCount += toBeActiveCount
  $container.find('.js-button-see-more').attr('data-count', loadedCount)
  $container.find('.js-button-see-more').attr('data-count-max', maxPossible)
  showHideSeeMoreButton($container, maxPossible)
  showToBeActive($container)
}
const btnSearch = ($container) => {
  resetSeeMoreButton($container)
  $container.find('.js-btn-search').on('click', function (e) {
    e.preventDefault()
    resetSeeMoreButton($container)
    doBtnSearch($container, 'searchInputValue')
  })
  $container.find('.js-btn-search').on('search', function (e, type) {
    e.preventDefault()
    resetSeeMoreButton($container)
    doBtnSearch($container, type)
  })
}

const handleResetSearchCriterias = ($) => {
  $('.js-connector-plugins-listing').each(function () {
    let $this = $(this)
    $this.find('.js-btn-reset-search').on('click', function (e) {
      e.preventDefault()
      $this.find('#autoComplete').val('')
      doBtnAll($, $this)
      $this.find('.js-btn-search').first().trigger('search', ['selectedFilter'])
    })
  })
}
const connectorPluginListing = ($) => {
  connectorPluginListingTypeFilter($)
  btnSeeMore($('.js-connector-plugins-listing'))
  btnAll($)
  handleAutoComplete($)
  handleResetSearchCriterias($)
  $('.js-connector-plugins-listing').each(function () {
    let $container = $(this)
    doBtnSearch($container)
    btnSearch($container)
  })
}

export default connectorPluginListing
