import $ from 'jquery'
import replaceQueryParam from './replaceQueryParam'

export const resetFilters = () => {
  let resetFilter = $('#resetFilterId')
  resetFilter.on('click', function (e) {
    e.preventDefault()
    var str = window.location.search
    str = replaceQueryParam('content_type_select', '', str)
    str = replaceQueryParam('industry_select', '', str)
    str = replaceQueryParam('topic_select', '', str)
    str = replaceQueryParam('tag_select', '', str)
    window.location = window.location.pathname + str
  })
}
