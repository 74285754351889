import 'jquery'

const handleFooterNavMobile = ($) => {
  $('.js-footer-closes-nav').each(function () {
    let $this = $(this)
    let target = $this.attr('data-target');
    $this.on('click', function () {
      if (!$(target).hasClass('active')) {
        $this.addClass('active')
        $(target).addClass('active')
      } else {
        $this.removeClass('active');
        $(target).removeClass('active');
      }
    })
  })
}

export default handleFooterNavMobile
