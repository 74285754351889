/**
 * @param {jQuery} $
 * @param  {String} targetClassName
 */
const generalHigDropDown = ($, targetClassName) => {
  const targetClass = '.' + targetClassName
  $(targetClass).each(function () {
    let $this = $(this)
    const valueName = $this.attr('data-value-target')
    let GET = window.location.search.replace('?', '').split('&')
    if (GET.length > 0) {
      let value = GET.filter(el => el.split('=')[0] === valueName)

      if (value.length > 0) {
        value = value[0].split('=')[1]
      } else {
        value = false
      }

      if (value) {
        selectOption($this, value, valueName)
      }
    }
    // const value = window.location.search.split('&').filter(el => el.split('=')[0] === valueName)[0].split('=')[1]
    $this.find('.js-selected-button').on('click', function (e) {
      e.preventDefault()
      toggleDropDown($this)
      handleSelectOption($this, valueName)
    })
  })
}

const handleSelectOption = ($container, valueName) => {
  $container.find('.js-connector-plugin-type-filter-button')
    .each(function () {
      let $filterButton = $(this)
      $filterButton.on('click', function (e) {
        e.preventDefault()
        let $clickedButton = $(this)
        if (!$clickedButton.hasClass('active')) {
          $clickedButton.addClass('to-be-active')
        } else {
          $container.find('.js-connector-plugin-type-filter-button').first().addClass('to-be-active')
        }
        $container.find('.js-connector-plugin-type-filter-button.active').removeClass('active')
        setTimeout(function () {
          updateDropdownData($container, valueName)
        } , 100)
      })
    })
}

const selectOption = ($container, value, valueName) => {
  const $clickedButton = $container.find('[data-target=' + value + ']')
  $container.find('[name=' + valueName + ']').val(value)
  // '.js-connector-plugin-type-filter-button.active'
  if (!$clickedButton.hasClass('active')) {
    $clickedButton.addClass('to-be-active')
  } else {
    $container.find('.js-connector-plugin-type-filter-button').first().addClass('to-be-active')
  }
  $container.find('.js-connector-plugin-type-filter-button.active').removeClass('active')
  setTimeout(function () {
    updateDropdownData($container, value)
  } , 100)
}

const updateDropdownData = ($container, valueName, defaultValue) => {
  let $valueContainer = $container.find('[name="' + valueName + '"]')
  $container.find('.to-be-active')
    .removeClass('to-be-active')
    .addClass('active')
  let $selectedButton = $container.find('.js-connector-plugin-type-filter-button.active')
  $valueContainer.val($selectedButton.attr('data-target'))
  $container.find('.js-selected-text').html($selectedButton.find('.js-button-label').text())
  $valueContainer.val($selectedButton.attr('data-target'))

  closeDropDown($container)
  if ($selectedButton.length) {
    $container.trigger('change', $selectedButton.attr('data-target'))
  } else {
    $container.trigger('change', defaultValue)
  }
}

const closeDropDown = ($container) => {
  let $dropDownContent = $container.find('.js-hig-drop-down-options')
  if ($dropDownContent.hasClass('active')) {
    $dropDownContent.removeClass('active')
    $container.closest('.js-general-hig-drop-down').find('.arrow-up').addClass('d-none')
    $container.closest('.js-general-hig-drop-down').find('.arrow-down').removeClass('d-none')
  }
}
const toggleDropDown  = ($container) => {
  let $dropDownContent = $container.find('.js-hig-drop-down-options')
  if (!$dropDownContent.hasClass('active')) {
    $dropDownContent.addClass('active')
    $container.closest('.js-general-hig-drop-down').find('.arrow-up').removeClass('d-none')
    $container.closest('.js-general-hig-drop-down').find('.arrow-down').addClass('d-none')
  } else {
    closeDropDown($container)
  }
}

export default generalHigDropDown
