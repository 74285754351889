import $ from 'jquery'

const imageVideoModal = ($) => {
  $('.js-video-modal-button').each (function () {
    $(this).on('click', function () {
      let $target = $($(this).attr('data-target'))
      if (typeof $target.modal === 'undefined') {
        $target = window.$($(this).attr('data-target'))
      }
      if (typeof $target.modal !== 'undefined') {
        $target.modal('show')
        let wistiaEmbedId = $target.find('.wistia_embed_initialized').first().attr('id')

        if (typeof window.wistiaEmbeds !== 'undefined') {
          window.wistiaEmbeds.each(el => {
            if (el.container.id === wistiaEmbedId) {
              el.play()
            }
          })
        }
        //window.wistiaEmbeds
        $target.on('shown.bs.modal', function () {
          $target.find('.js-modal-close-button').unbind('click')
          $target.find('.js-modal-close-button').on('click', function () {
            $target.modal('hide')
          })
        })
      }
    })
  })
}

export default imageVideoModal
