import 'jquery'

const pricePlanAssetCountSelect = ($) => {
  const updatePrice = ($select) => {
    let $target = $($select.attr('data-price-target'))
    $target.html($select.val());
    let selectedIndex = $select[0].selectedIndex
    let selectedOption = $select[0][selectedIndex]
    let assets = selectedOption.dataset.assets;
    let streams = selectedOption.dataset.streams;

    $($select.attr('data-assets-count-target')).html(assets)
    $($select.attr('data-stream-count-target')).html(streams)
  }
  $('.js-assets-select').each(function () {
    $(this).on('change', function () {
      updatePrice($(this))
    })
    updatePrice($(this))
  })
}

export default pricePlanAssetCountSelect

