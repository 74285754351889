import 'jquery'
import $ from 'jquery'
import replaceQueryParam from './replaceQueryParam'

const handleContactUsFormSwap = ($) => {
  let buttons = [$('.js-sign-up-free-cta'), $('.js-contact-us-cta')]
  buttons.forEach($button => {
    if ($button.length) {
      $button.on('click', function (e) {
        e.preventDefault()
        let targetFormSelector = $(this).attr('data-target')
        updateFormsVisibility(targetFormSelector)
        updateCards($([$('.js-sign-up-free-cta'), $('.js-contact-us-cta')]), $button)
        $([document.documentElement, document.body]).animate({
          scrollTop: $('.block--contact-page-forms').offset().top - 150
        }, 300, 'linear');
        updateURL(targetFormSelector)
      })
    }
  })
  const updateURL = (targetFormSelector) => {
    // update url but don't relaod
    let nextURL = window.location.search
    nextURL = nextURL.replace(/\/?form=.*$/, 'form=' + $(targetFormSelector).attr('data-form-id') + '#form');
    if (nextURL.indexOf('form') === -1 ) {
      nextURL = '?form=' + $(targetFormSelector).attr('data-form-id') + '#form'
    }
    window.history.replaceState(
      { additionalInformation: 'Updated the URL with JS' },
      nextURL,
      nextURL
    )
  }
  const updateCards = ($buttons, $thisButton) => {
    $buttons.each(function() {
      let $actual = $(this)
      let $actualCard = $(this).closest('.js-card-contact-page-hero-card')
      $actual
        .removeClass('btn-outline-primary')
        if (!$actual.hasClass('btn-outline-light')) {
          $actual.addClass('btn-outline-light')
        }
      $actualCard
        .removeClass('active')
        if (!$actualCard.hasClass('not-active')) {
          $actualCard.addClass('not-active')
        }
    })

    $thisButton.removeClass('btn-outline-light')
    if (!$thisButton.hasClass('btn-outline-primary')) {
      $thisButton.addClass('btn-outline-primary')
    }

    let $thisCard = $thisButton.closest('.js-card-contact-page-hero-card')
    $thisCard
      .removeClass('not-active')
    if (!$thisCard.hasClass('active')) {
      $thisCard.addClass('active')
    }
  }
  const updateFormsVisibility = (targetFormSelector) => {
    $('.js-marketo-form-block').each(function () {
      let $form = $(this)
      if (!$form.hasClass('d-none')) {
        $form.addClass('d-none')
      }
    })
    $(targetFormSelector).removeClass('d-none')
  }
}

export default handleContactUsFormSwap
