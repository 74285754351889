import 'slick-carousel'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'

const slickCarouselConfig = ($) => {
  $('.logos.slick').slick({
    dots: true,
    autoplaySpeed: 5000,
    speed: 4000,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 8000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          arrows: true
        }
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true
        }
      },
      {
        breakpoint: 744,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false
        }
      },
      {
        breakpoint: 375,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ]
  });

  $('.use-case-carousel').each(function () {
    window.tempId = $(this).attr('id')
    let $slick = $('#' + window.tempId + ' .slick')
    if (window.tempId !== undefined) {
      $slick.slick({
        dots: true,
        // autoplaySpeed: 5000,
        // speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        prevArrow: $slick.attr('data-prev'), //'.js-prev',
        nextArrow: $slick.attr('data-next'), //'.js-next',
        appendDots: $slick.attr('data-dots'), //'.js-dots',
        // autoplaySpeed: 2000,
      });
      $slick.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        let classes = $(slick.$slides[nextSlide]).find('.card-use-case-carouse').first().attr('class').split(' ');
        if (classes.includes('bg-000') && !$(this).hasClass('dark')) {
          $(this).closest('.use-case-carousel').addClass('dark')
        } else {
          $(this).closest('.use-case-carousel').removeClass('dark')
        }
      });
      window.tempId = undefined
    }
  })

}

export default slickCarouselConfig
