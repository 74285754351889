import $ from 'jquery'

const handleAnchorLinkScrollTo = ($) => {
  let hash = window.location.hash

  if (hash && hash.length > 2) {
    const tabId = hash.replace('#', '#tab-')
    console.log(tabId)
    if ($(tabId).length) {
      if (typeof $(tabId).tab !== 'undefined') {
        if ($(tabId).hasClass('nav-link')) {
          $(tabId).tab('show')
        }
      } else {
          if (typeof window.$(tabId).tab !== 'undefined') {
            if (window.$(tabId).hasClass('nav-link')) {
              window.$(tabId).tab('show')
            }
          }

          if (typeof window.jQuery !== 'undefined') {
            if (typeof window.jQuery(tabId).tab !== 'undefined') {
              if (window.jQuery(tabId).hasClass('nav-link')) {
                window.jQuery(tabId).tab('show')
              }
            }
          }

      }
      // this should be replaced with scroll-behavior: smooth; if it worked with the complex animation
      setTimeout(function () {
        $([document.documentElement, document.body]).animate({
          scrollTop: $(tabId).offset().top - 150
        }, 1300); // if you know what is the callback for gsap rendered state, change this setTimeout!
      }, 1000)
    }
  }
}

export default handleAnchorLinkScrollTo
