import $ from 'jquery'

export const eventCardSpeakerHandler = () => {
  $('.js-event-speaker').each(function () {
    const target = '#' + $(this).attr('data-speaker-details')
    const showTarget = (target) => {
      $(target).addClass('active')
      setTimeout(function () {
          $(target).addClass('show')
      }, 105);
    }

    const hideTarget = (target) => {
      $(target).removeClass('show')
      setTimeout(function () {
        $(target).removeClass('active')
      }, 105);
    }
    const handleEventSpeakerDetails = (target) => {
      hideTarget('.js-speaker-detail:not(' + target + ')')
      $('.js-event-speaker').removeClass('active')
      if (!$(target).hasClass('active')) {
        $(this).addClass('active')
        showTarget(target)
      } else {
        hideTarget('.js-speaker-detail')
      }
    }

    $(this).on('click', function () {
      handleEventSpeakerDetails(target)
    })
  })
}
