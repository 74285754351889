import $ from 'jquery'
import 'jquery-match-height'

export const matchEventCardsHeight = () => {
  console.log('matchEventCardsHeight')
  const fields = ['.js-event-card-eyebrow' ,'.js-event-card-text' ,'.js-event-card-speakers']
  // fields.forEach(el => ($(el).matchHeight({remove: true})))
  //
  // fields.forEach(el => $(el).matchHeight({remove: true}))
  // fields.forEach(el => $(el).matchHeight({row: true}))


  // $('.js-event-card-eyebrow').matchHeight({row: true})
  // $('.js-event-card-text').matchHeight({row: true})
  // $('.js-event-card-speakers').matchHeight({row: true})
}
export const eventListingSeeMore = () => {
  $('.js-event-list-card-list').each(function () {
    const $cardList = $(this)
    const id = $cardList.attr('id')
    const $btnSeeMore = $('#' + 'seeMore_' + id)
    if ($cardList.find('.js-card-event.d-none').length === 0) {
      $btnSeeMore.hide()
    }

    $btnSeeMore.on('click', function () {
      const $inactiveCards = $cardList.find('.js-card-event.d-none')

      for (let i = 0; i < parseInt($(this).attr('data-posts-per-page')); i++) {
        if ($inactiveCards[i]) {
          $($inactiveCards[i]).removeClass('d-none')
          $.fn.matchHeight._update()
        }
      }
      if ($cardList.find('.js-card-event.d-none').length === 0) {
        $(this).hide()
      }
    })
  })
}
