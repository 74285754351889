// import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import CSSPlugin from 'gsap/CSSPlugin'
import DrawSVGPlugin from './../gsapPlugins/DrawSVGPlugin'
// ClassNamePlugin START (requires GSAP 3.2.2 or later)
gsap.registerPlugin({
  name: 'className',
  init: true,
  register (gsap, Plugin) {
    var CSSPlugin = gsap.plugins.css,
      cssCore = CSSPlugin.core || console.warn('Requires GSAP 3.2.1 or later') || {},
      _removeLinkedListItem = gsap.core._removeLinkedListItem,
      _removeProperty = cssCore._removeProperty,
      PropTween = gsap.core.PropTween,
      _getAllStyles = function (target, uncache) {
        var styles = {},
          computed = getComputedStyle(target),
          cache = target._gsap,
          p
        for (p in computed) {
          if (isNaN(p) && p !== 'cssText' && p !== 'length') {
            styles[p] = computed[p]
          }
        }
        uncache && cache && (cache.uncache = true)
        gsap.getProperty(target, 'x')
        cache = target._gsap
        for (p in cache) {
          styles[p] = cache[p]
        }
        return styles
      }
    Plugin.prototype.init = function (target, endValue, tween) {
      let startClassList = target.getAttribute('class'),
        style = target.style,
        cssText = style.cssText,
        cache = target._gsap,
        classPT = cache.classPT,
        inlineToRemoveAtEnd = {},
        end = (endValue.charAt(1) !== '=') ? endValue : startClassList.replace(new RegExp('(?:\\s|^)' + endValue.substr(2) + '(?![\\w-])'), '') + ((endValue.charAt(0) === '+') ? ' ' + endValue.substr(2) : ''),
        plugin = this,
        changingVars = {},
        startVars = _getAllStyles(target),
        transformRelated = /(transform|perspective)/i,
        css = new CSSPlugin(),
        _renderClassName = function (ratio) {
          css.render(ratio, css)
          if (!ratio || ratio === 1) {
            target.setAttribute('class', ratio ? end : startClassList)
            for (var p in inlineToRemoveAtEnd) {
              _removeProperty(target, p)
            }
          }
        },
        endVars, p
      if (classPT) {
        classPT.r(1, classPT.d)
        _removeLinkedListItem(classPT.d, classPT, '_pt')
      }
      target.setAttribute('class', end)
      endVars = _getAllStyles(target, true)
      target.setAttribute('class', startClassList)
      for (p in endVars) {
        if (endVars[p] !== startVars[p] && !transformRelated.test(p)) {
          changingVars[p] = endVars[p]
          if (!style[p] && style[p] !== '0') {
            inlineToRemoveAtEnd[p] = 1
          }
        }
      }
      cache.classPT = plugin._pt = new PropTween(null, target, 'className', 0, 0, _renderClassName, plugin, 0, -11)
      if (style.cssText !== cssText) {
        style.cssText = cssText
      }
      cache.uncache = true
      gsap.getProperty(target, 'x')
      css.init(target, changingVars, tween)
      plugin._props.push.apply(plugin._props, css._props)
      return 1
    }
  }
})
// ClassNamePlugin END

export const animatedWorkflow = () => {
  // Grab a reference to the media query to see if we can use full animation
  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
  if (!mediaQuery || mediaQuery.matches) {
    // reduced motion declarations
  } else {
    gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)
    gsap.set('.block--animated-workflow .workflow-text .step-text', { translateY: '20px' })
    gsap.set('.block--animated-workflow  svg #step-4-icon-bg', { fill: '#303030' })
    gsap.set('.block--animated-workflow svg .lines path', { drawSVG: '0% 0%' })
    gsap.set('.block--animated-workflow svg .lines.reverse-dir path', { drawSVG: '100% 100%' })
    gsap.set('.block--animated-workflow svg #step-1 .lines path', { drawSVG: '0% 100%' })
    let pinPoint = 'top 1120px'
    if(window.innerWidth > 768) {
      pinPoint = 'center center'
    }
    var workflowSVG = gsap.timeline({
      id: 'workflow',
      toggleActions: 'play none play none',
      scrollTrigger: {
        trigger: '.block--animated-workflow .row:nth-of-type(2)',
        // trigger: '.block--animated-workflow svg',
        anticipatePin: 1,
        pin: true,
        start: 'top 120px',
        // start: 'center center',
        // start: pinPoint,
        end: '+=5000',
        invalidateOnRefresh: true,
        scrub: true
      }
    })

    const leftSteps = gsap.utils.toArray('.block--animated-workflow svg #step-8-left .steps')
    const rightSteps = gsap.utils.toArray('.block--animated-workflow svg #step-8-right .steps')
    // const step4 = gsap.utils.toArray(".block--animated-workflow svg .step-4.steps");

    leftSteps.forEach((step, index) => {
      workflowSVG.to(`.block--animated-workflow svg #step-${index + 2}`, {
        delay: 1.5,
        className: 'currentStep',
        opacity: 1,
        ease: 'ease-in'
      })
        .to(`.block--animated-workflow svg #step-${index + 2} .lines path`, {duration: 2, drawSVG: "0% 100%" })
        .to(`.block--animated-workflow svg #step-${index + 1}-number`, {
          opacity: 0,
          ease: 'linear'
        }, '<')
        .to(`.block--animated-workflow svg #step-${index + 2}-icon`, {
          fill: '#0ce0ff',
          ease: 'linear'
        }, '<')
        .to(`.block--animated-workflow svg #step-${index + 1}-icon`, {
          fill: '#aaa',
          ease: 'linear'
        }, '<')
      workflowSVG
        .to(`.block--animated-workflow .workflow-text #stepText${index + 1}`, {
          opacity: 0,
          ease: 'ease-out'
        }, '<')
        .to(`.block--animated-workflow .workflow-text #stepText${index + 2}`, {
          delay: 0.5,
          duration: 3,
          translateY: '0',
          opacity: 1,
          ease: 'ease-in'
        }, '<')
    })

    workflowSVG.to([`.block--animated-workflow svg #step-1-overlay`, `.block--animated-workflow svg #step-2-overlay`, `.block--animated-workflow svg #step-3-overlay`], {
      opacity: 0,
      ease: 'linear'
    })
    workflowSVG.to('.block--animated-workflow svg #step-4', {
      delay: 1.5,
      opacity: 1,
      ease: 'linear'
    }, '<')
      .to(`.block--animated-workflow svg #step-3-number`, {
        opacity: 0,
        ease: 'linear'
      }, '<')
      .to(`.block--animated-workflow svg #step-4-icon`, {
        fill: '#0ce0ff',
        ease: 'linear'
      }, '<')
      .to(`.block--animated-workflow svg #step-3-icon`, {
        fill: '#aaa',
        ease: 'linear'
      }, '<')


    rightSteps.forEach((step, index) => {
      workflowSVG.to(`.block--animated-workflow svg #step-${index + 5}`, {
        delay: 1.5,
        className: 'currentStep',
        opacity: 1,
        ease: 'linear'
      })
        .to(`.block--animated-workflow svg #step-${index + 5} .lines path`, {duration: 2, drawSVG: '0% 100%' })
      if (index == 0) {
        workflowSVG.to(`.block--animated-workflow svg #step-4-overlay`, {
          opacity: 0,
          ease: 'linear'
        }, '<')
        workflowSVG.to('.block--animated-workflow svg #step-4-icon-bg', {
          fill: '#000',
          opacity: 1,
          ease: 'linear'
        }, '<')
      }

      workflowSVG
        .to(`.block--animated-workflow .workflow-text #stepText${index + 4}`, {
          opacity: 0,
          ease: 'ease-out'
        }, '<')
        .to(`.block--animated-workflow .workflow-text #stepText${index + 5}`, {
          delay: 0.5,
          duration: 3,
          opacity: 1,
          translateY: '0',
          ease: 'ease-in'
        }, '<')

      workflowSVG.to(`.block--animated-workflow svg #step-${index + 4}-number`, {
        opacity: 0,
        ease: 'linear'
      }, '<')

      if (index == 1) {
        workflowSVG.to(`.block--animated-workflow svg #step-${index + 5}-icon`, {
          stroke: '#0ce0ff',
          ease: 'linear'
        }, '<')
      } else {
        workflowSVG.to(`.block--animated-workflow svg #step-${index + 5}-icon`, {
          fill: '#0ce0ff',
          ease: 'linear'
        }, '<')
      }

      if (index == 2) {
        workflowSVG.to(`.block--animated-workflow svg #step-${index + 4}-icon`, {
          stroke: '#aaa',
          ease: 'linear'
        }, '<')
      } else {
        workflowSVG.to(`.block--animated-workflow svg #step-${index + 4}-icon`, {
          fill: '#aaa',
          ease: 'linear'
        })
      }
    })

    workflowSVG.to('.block--animated-workflow svg #step-7-number', {
      delay: 1,
      opacity: 0,
      ease: 'linear'
    })
      .to(`.block--animated-workflow .workflow-text #stepText7`, {
        opacity: 0,
        translateY: '0',
        ease: 'ease-out'
      }, '<')
      .to('.block--animated-workflow svg #step-4-lines', {
        opacity: 0,
        ease: 'linear'
      }, '<')
    workflowSVG.to([`.block--animated-workflow svg #step-1-icon`, `.block--animated-workflow svg #step-2-icon`, `.block--animated-workflow svg #step-3-icon`, `.block--animated-workflow svg #step-4-icon`, `.block--animated-workflow svg #step-5-icon`, `.block--animated-workflow svg #step-6-icon`, `.block--animated-workflow svg #step-7-icon`], {
      opacity: 0,
      ease: 'linear'
    }, '<')
    workflowSVG.to([`.block--animated-workflow svg #step-5-overlay`, `.block--animated-workflow svg #step-6-overlay`, `.block--animated-workflow svg #step-7-overlay`], {
      opacity: 0,
      ease: 'linear'
    }, '<')
    workflowSVG.to(['.block--animated-workflow svg #step-7-line-bottom', '.block--animated-workflow svg #step-2-line-bottom', '.block--animated-workflow svg #step-2-line-top', '.block--animated-workflow svg #step-6-line-bottom', '.block--animated-workflow svg #step-5-line-bottom', '.block--animated-workflow svg #step-3-line-right', '.block--animated-workflow svg #step-3-line-top'], {
      opacity: 0.2,
      ease: 'linear'
    }, '<')
    workflowSVG.to('.block--animated-workflow svg #step-8', {
      opacity: 1,
      ease: 'linear'
    })
    workflowSVG.to(`.block--animated-workflow .workflow-text #stepText8`, {
        delay: 0.5,
      duration: 3,
      translateY: '0',
        opacity: 1,
        ease: 'ease-in'
      }, '<')
    workflowSVG.to(['.block--animated-workflow svg #step-1', '.block--animated-workflow svg #step-2', '.block--animated-workflow svg #step-3'], {
      translateX: '53px',
      translateY: '30px',
      ease: 'linear'
    })
    workflowSVG.to('.block--animated-workflow svg #step-8 #step-8-icon-twin-building', {
      translateX: '53px',
      translateY: '30px',
      ease: 'linear'
    }, '<')
    workflowSVG.to('.block--animated-workflow svg #step-8 #step-8-icon-monitoring', {
      translateX: '-53px',
      translateY: '-30px',
      ease: 'linear'
    }, '<')
    workflowSVG.to(['.block--animated-workflow svg #step-5', '.block--animated-workflow svg #step-6', '.block--animated-workflow svg #step-7'], {
      translateX: '-53px',
      translateY: '-30px',
      ease: 'linear'
    }, '<')
    workflowSVG.to('.block--animated-workflow svg #step-5-line-bottom', {
      opacity: 0,
      ease: 'linear'
    }, '<')
    workflowSVG.to('.block--animated-workflow svg .shadow-half', {
      opacity: 1,
      ease: 'linear'
    }, '<')
    workflowSVG.to('.block--animated-workflow', {
      duration: 8
    })
  }
}
